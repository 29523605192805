<template>
	<div class="text-center ma-auto">
		Right Side Actions
	</div>
</template>

<script>
export default {
	name: "RightSideActions"
};
</script>

<style scoped></style>
