<template>
	<div class="full-width">
		<!--    <div>-->
		<!--      Lënda {{ state.classroom.subject }}-->
		<!--    </div>-->
		<!--    <div>-->
		<!--      Viti {{ state.classroom.groupYear }}-->
		<!--    </div>-->
		<!--    <div>-->
		<!--      Grupi {{ state.classroom.groups }}-->
		<!--    </div>-->

		<v-tabs
			background-color="tab"
			color="primaryLight"
			show-arrows
			centered
			style="
				position: sticky;
				top: 0;
				z-index: 996;
				border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
			"
			v-model="state.currentTab"
		>
			<v-tab v-for="tab in state.tabs" :key="tab.name" :to="`${tab.name}`">
				{{ tab.text }}
			</v-tab>
		</v-tabs>

		<!-- TODO: not flex -->
		<div class="fill-height d-flex" style="position: relative">
			<!--			<LeftSideActions v-if="$screen.width >= 1200" style="width: 20%" />-->

			<!-- View based on selected tab -->
			<transition name="fade" mode="out-in">
				<Preloader v-if="isLoading" />
				<router-view
					v-else
					:style="$screen.width >= 1200 ? 'width: 60%' : 'width: 100%'"
					:class="$isMobileOnly ? 'pa-2' : 'pa-4'"
					style="max-height: calc(100% - 48px - var(--safe-area-inset-top))"
				>
				</router-view>
			</transition>

			<!--			<RightSideActions v-if="$screen.width >= 1200" style="width: 20%" />-->
		</div>
	</div>
</template>

<script>
import { computed, onMounted, reactive } from "@vue/composition-api";
import Post from "../components/forum/Post";
import AddComment from "../components/forum/AddComment";
import RightSideActions from "../components/forum/RightSideActions";
import LeftSideActions from "../components/forum/LeftSideActions";
import Preloader from "../components/general/Preloader";
import ClassroomService from "../services/classroom.service";

export default {
	name: "Classroom",
	components: {
		Preloader,
		LeftSideActions,
		RightSideActions,
		AddComment,
		Post,
	},
	props: [""],
	setup(props, { root }) {
		const state = reactive({
			classroom: computed(() =>
				ClassroomService.getClassroomById(root.$route.params.classroomId)
			),

			tabs: [
				{
					id: 1,
					name: "forum",
					text: "Bashkëbisedim",
					children: [
						{
							name: "student",
							text: "Nxënës",
						},
					],
				},
				{ id: 2, name: "materials", text: "Materiale" },
				{ id: 3, name: "assignments", text: "Detyra" },
				{ id: 4, name: "interactive-books", text: "Libri interaktiv" },
				{ id: 5, name: "calendar", text: "Kalendari" },
			],
			currentTab: "forum",
		});

		onMounted(() => {
			console.log("classroomId", root.$route.params.classroomId);
		});

		const isLoading = computed(() => root.$store.getters["general/isLoading"]);

		return {
			state,

			isLoading,
		};
	},
};
</script>

<style lang="scss" scoped>
.sticky-banner::v-deep {
	.v-banner__wrapper {
		padding: 0;
		border: none !important;
	}
}
</style>
