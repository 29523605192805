<template>
	<div class="text-center ma-auto">
		Left Actions
	</div>
</template>

<script>
export default {
	name: "LeftSideActions"
};
</script>

<style scoped></style>
